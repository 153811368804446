import React, { Component } from "react";
import { connect } from 'react-redux';
import { login } from '../../features/usuario/usuarioSlice';
import api from "../../services/api";
import qs from 'query-string';
import './Login.css';
import config from "../../config";
import { base64ToStr } from "../../scripts/base64Util";
const mapDispatchToProps = { login }

const pathValidos = ['/','/certidoes','/carteira','/notificacoes'];

export const Login =

// redux
connect(
	null,
	(mapDispatchToProps)
)(
	class extends Component {

		_isMounted = false;

		constructor(props){
			super(props);
			this.state = {
				isLoading: false,
				error: ""
			};
			this.handleLogin = this.handleLogin.bind(this);
		}

		render() {
			return(
				<div>
					{this.state.isLoading && 
						<div className="load-message">
							<div className="loader"></div>
							{/* <p>Efetuando login...</p> */}
						</div>
					}

					{this.state.error &&
						<div className="br-message danger" role="alert">
							<div className="icon">
								<i className="fas fa-times-circle fa-lg" aria-hidden="true"></i>
							</div>
							<div className="content">
								<span className="message-body">{this.state.error}</span>
							</div>
							<div className="close">
							<button className="br-button circle small" type="button" aria-label="Fechar" onClick={() => alert('s')}>
								<i className="fas fa-times" aria-hidden="true"></i>
							</button>
							</div>
						</div>
					}

				</div>
			);
		}

		componentDidMount(){
			this._isMounted = true;
			this.handleLogin();
		}

		componentWillUnmount() {
			this._isMounted = false;
		}

		handleLogin = async e => {
			const urlParams = qs.parse(this.props.location.search);
			const code = urlParams.code;
			
			if(!code) {
				if(this._isMounted) this.setState({ error: "Code não informado!" });
			} else {
				try {
					if(this._isMounted) this.setState({isLoading: true, error: ""});
					const estado = urlParams.state;
					const response = await api.get(`/api/login/${code}?urlRetorno=${config.govbrRedirectUri}&state=${estado}`);
					const {token, foto, state} = response.data;
					this.props.login({token, foto});
					let path = '/';
					if (state) {
						try {
							const pathState = JSON.parse(base64ToStr(state)).path;
							if(pathValidos.includes(pathState)) {
								path = pathState;
							}
						} catch (e) {
							console.log("Erro na atribuição do path", e);
						}
					}
					this.props.history.push(path);
				} catch (err) {
					//TODO: tratar erro
					console.log('Erro durante o processamento do login', err);
					if(this._isMounted) this.setState({
						error: "Erro durante o processamento do login."
					});
				} finally {
					if(this._isMounted) this.setState({isLoading: false});
				}
			}
		}

	}

); // redux
