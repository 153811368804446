import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { PrivateRoute } from './Components/PrivateRoute';
import Home from './Components/Home';
import Notificacoes from './Components/Notificacoes';
import DetalhamentoNoficacao from './Components/Notificacoes/DetalhamentoNotificacao';
import Carteira  from './Components/Carteira';
import GeradorPDFBarraGov  from './Components/GeradorPDFBarraGov/index.';
import Certidoes from './Components/Certidoes';
import {Login} from './Components/Login';
import "./App.css";
import FrontChanelLogout from './Components/FrontChanelLogout';
import PaginaHistoricoDeServicos from './Components/PaginaHistoricoDeServicos';
import GlobalStyles from './styles/global'
import { ProvidersPaginaHistoricoDeServicos } from './Components/PaginaHistoricoDeServicos/ProvidersPaginaHistoricoDeServicos';

function App() {
  return (
    <React.Fragment>
		<BrowserRouter>
			<GlobalStyles />
			<Switch>
				<Route exact path="/login" component={Login} />
				<Route exact path="/front-channel-logout" component={FrontChanelLogout} />
				<PrivateRoute exact path="/" component={() => <Home/>} />
				<PrivateRoute exact path="/notificacoes" component={() => <Notificacoes/>} />
				<PrivateRoute exact path="/detalhamentoNotificacao" component={() => <DetalhamentoNoficacao/>} />
				<PrivateRoute exact path="/carteira" component={() => <Carteira/>} />
				<PrivateRoute exact path="/certidoes" component={() => <Certidoes/>} />
				<PrivateRoute exact path="/geradorPDFBarraGov" component={() => <GeradorPDFBarraGov/>} />				
				<PrivateRoute exact path="/consultarservicossolicitados" component={() => (
					<ProvidersPaginaHistoricoDeServicos>
					  <PaginaHistoricoDeServicos />
					</ProvidersPaginaHistoricoDeServicos>
				)} />
			</Switch>
		</BrowserRouter>
    </React.Fragment>
  );
}

export default App;
