import React, {Fragment} from 'react';
import PainelMinhaConta from '../PainelMinhaConta';
import PainelServicos from '../PainelServicos';

export default function PainelPrincipal  (props) {

    return (
            <Fragment>
                <PainelMinhaConta/>
                <PainelServicos/>
            </Fragment>
        )
}